import { useState } from 'react';
import { Table, Form, Tooltip, Space, Button, Tag, Typography } from 'antd';
import { useEffect } from 'react';
import {
    ExperimentOutlined,
    EditOutlined,
    RocketOutlined,
    UnorderedListOutlined,
    UsergroupAddOutlined,
    CalculatorOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ResultTypeData } from '@models/result-type-model';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: ResultTypeData[];
    total: number;
    loading: boolean;
    onChangePagination?: (page: number, pageSize: number) => void;
    onEdit: (resultType: ResultTypeData) => void;
    onDelete: (resultType: ResultTypeData) => void;
    onShowResults: (resultType: ResultTypeData) => void;
    onShowMembers: (resultType: ResultTypeData) => void;
    onShowEvents: (resultType: ResultTypeData) => void;
    onShowStartups: (resultType: ResultTypeData) => void;
    onShowRids: (resultType: ResultTypeData) => void;
    onShowFinances: (resultType: ResultTypeData) => void;
}

export const ContentTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<ResultTypeData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: t('result_types.content.title.title'),
            dataIndex: 'title',
            width: '40%',
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Space direction="vertical">
                        <Tag style={{ color: 'black' }} color={record.primary_color ? record.primary_color : undefined}>
                            {record.title}
                        </Tag>
                        <Typography.Text>{record.description}</Typography.Text>
                    </Space>
                );
            }
        },
        {
            title: t('result_types.content.member_count.title'),
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('result_types.content.member_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowMembers(record)}
                            disabled={record.member_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.member_count ? 1 : 0.3 }}>
                                <UsergroupAddOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.member_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result_types.content.event_count.title'),
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('result_types.content.event_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowEvents(record)}
                            disabled={record.event_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.event_count ? 1 : 0.3 }}>
                                <UnorderedListOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.event_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result_types.content.startup_count.title'),
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('result_types.content.startup_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowStartups(record)}
                            disabled={record.startup_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.startup_count ? 1 : 0.3 }}>
                                <RocketOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.startup_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result_types.content.rid_count.title'),
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('result_types.content.rid_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowRids(record)}
                            disabled={record.rid_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.rid_count ? 1 : 0.3 }}>
                                <ExperimentOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.rid_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result_types.content.finances_count.title'),
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('result_types.content.finances_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowFinances(record)}
                            disabled={record.finance_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.finance_count ? 1 : 0.3 }}>
                                <CalculatorOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.finance_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result_types.content.show_results.title'),
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('result_types.content.show_results.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowResults(record)}
                            disabled={record.result_count === 0}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.result_count ? 1 : 0.3 }}>
                                <Typography.Text strong style={{ fontSize: 18, paddingTop: 3 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.result_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            dataIndex: 'operations',
            render: (_: any, record: ResultTypeData) => {
                return (
                    <Tooltip title={t('common.edit')}>
                        <Button onClick={() => props.onEdit(record)} style={{}}>
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                bordered
                loading={props.loading}
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={false}
            />
        </Form>
    );
};
