import { Button, Card, DatePicker, Divider, Drawer, Empty, Form, Input, Select, Space, Spin, Switch, Typography, notification } from 'antd';
import '@assets/css/App.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectLiteral } from '@utils/object.utils';
import { DictionarySchemeColumn } from '@models/dictionary-model';
import TextArea from 'antd/es/input/TextArea';
import { stringToDate } from '@utils/date.utils';
import dayjs from 'dayjs';
import { useViewModel } from '@models/model';
import { DictionaryEditViewModel } from './dictionary.edit.model';
import { observer } from 'mobx-react-lite';

interface Props {
    visible: boolean;
    primaryKey: string;
    data: ObjectLiteral;
    altname: string;
    schemeColumns: DictionarySchemeColumn[];
    onClose: () => void;
    onCreate: (data: ObjectLiteral) => void;
    onUpdate: (id: string, data: ObjectLiteral) => void;
}

const EditData: React.FunctionComponent<Props> = observer((props) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new DictionaryEditViewModel({
                ...props,
                t,
                showError: showErrorNotification,
                showSuccess: showSuccessNotification
            })
    );

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.data]);

    const onClickSave = async () => {
        try {
            const valFromFields: Record<string, any> = form.getFieldsValue();
            viewModel.save(valFromFields);
        } catch (error) {
            showErrorNotification(`${t('common.save')}: ${error}`, '');
        }
    };

    const prepareDataForForm = () => {
        let converted: ObjectLiteral = {};

        props.schemeColumns.map((item) => {
            if (!viewModel.data[item.key]) return undefined;
            switch (item.type) {
                case 'date':
                    converted[item.key] = dayjs(stringToDate(props.data[item.key]));
                    break;
                default:
                    converted[item.key] = props.data[item.key];
            }
            return item;
        });
        return converted;
    };

    return (
        <Drawer
            forceRender
            title={viewModel.isNew ? t('common.create') : t('common.edit')}
            width={500}
            onClose={viewModel.close}
            open={props.visible}
            extra={
                <Space>
                    <Button onClick={onClickSave} type="primary">
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {contextHolder}
            {viewModel.data[props.primaryKey] && (
                <div>
                    <Card title={`${t('dictionaries.edit.id')}: '${props.primaryKey}'`} size="small">
                        <Typography.Text copyable>{props.data.id}</Typography.Text>
                    </Card>
                    <Divider />
                </div>
            )}

            <Spin spinning={viewModel.loading}>
                <Form layout="vertical" form={form} initialValues={prepareDataForForm()} requiredMark={true} key={'qweqweqqwq'}>
                    {props.schemeColumns
                        .filter((item) => {
                            if (item.key === props.primaryKey) return false;
                            if (item.is_editable !== undefined && !item.is_editable) return false;
                            return true;
                        })
                        .map((column, index) => {
                            if (column.is_dictionary && column.dictionary_altname !== undefined) {
                                return (
                                    <Form.Item
                                        key={`form-item-${index}`}
                                        name={column.key}
                                        label={column.name}
                                        rules={[{ required: column.is_required, message: t('dictionaries.edit.required') || '' }]}
                                    >
                                        <Select
                                            showSearch
                                            notFoundContent={<Empty description={''} />}
                                            placeholder={t('common.search')}
                                            onSearch={(value: string) => viewModel.searchDictionary(column.key, column.dictionary_altname!, value)}
                                            loading={viewModel.isLoadingDictionary}
                                            filterOption={false}
                                        >
                                            {viewModel.dictionaries
                                                .slice()
                                                .filter((i) => i.altname === column.dictionary_altname)
                                                .map((value: ObjectLiteral, dictIndex: number) => (
                                                    <Select.Option key={`dict_${column.key}_${dictIndex}`} value={value[column.key]}>
                                                        <Typography.Text>
                                                            {value[column.key]} — {value.name || value.title || value[column.key]}
                                                        </Typography.Text>
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                );
                            } else {
                                switch (column.type) {
                                    case 'string':
                                        return (
                                            <Form.Item
                                                key={`form-item-${index}`}
                                                name={column.key}
                                                label={column.name}
                                                rules={[{ required: column.is_required, message: t('dictionaries.edit.required') || '' }]}
                                            >
                                                <TextArea rows={4} />
                                            </Form.Item>
                                        );
                                    case 'boolean':
                                        return (
                                            <Form.Item key={`form-item-${index}`} name={column.key} label={column.name} valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                        );
                                    case 'date':
                                        return (
                                            <Form.Item
                                                key={`form-item-${index}`}
                                                name={column.key}
                                                label={column.name}
                                                rules={[{ type: 'object' as const }]}
                                            >
                                                <DatePicker showTime format={'DD.MM.YYYY HH:mm'} />
                                            </Form.Item>
                                        );
                                    default:
                                        return (
                                            <Form.Item
                                                key={`form-item-${index}`}
                                                name={column.key}
                                                label={column.name}
                                                rules={[{ required: column.is_required, message: t('dictionaries.edit.required') || '' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        );
                                }
                            }
                        })}
                </Form>
            </Spin>
        </Drawer>
    );
});

export default EditData;
